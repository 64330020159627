<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary"
              class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>Stock por almacén - {{ this.document.numberPart }}</h4>
      <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <component id="divPrint" :is="scrollbarTag" class="scroll-area--data-list-add-new" :settings="settings"
               :key="$vs.rtl">
      <div class="p-6">
        <div v-if="progress" class="mt-4 mb-4" style="width: 100%">
          <vs-progress indeterminate color="primary"></vs-progress>
        </div>
        <div>
          <vs-button @click="exportToExcel" color="dark" type="border" class="w-full mb-8">Descargar</vs-button>
        </div>
        <!--List-->
        <div class="mt-8">
          <vs-table class="mt-5" :data="stocks">
            <template slot="thead">
              <vs-th>Almacén</vs-th>
              <vs-th>Stock</vs-th>
            </template>

            <template slot-scope="{data}">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                <vs-td>
                  <p> {{ tr.warehouse }}</p>
                </vs-td>

                <vs-td>
                  <p> {{ tr.stock }}</p>
                </vs-td>

              </vs-tr>
            </template>
          </vs-table>
        </div>
      </div>
    </component>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import trimString from '../../mixins/trimString'

import { db } from '@/firebase/firebaseConfig'

const _ = require('lodash')

export default {
  props: {
    data: {
      type: Object,
      default: () => {
      }
    },
    isSidebarActive: {
      type: Boolean,
      required: true
    }
  },
  components: {
    VuePerfectScrollbar
  },
  mixins: [trimString],
  data () {
    return {
      settings: { // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: .60
      },
      document: {},
      stocks: [],
      progress: false
    }
  },
  watch: {
    async isSidebarActive (val) {
      if (!val) return
      this.document = {
        ...this.data
      }
      await this.getStocks(this.document)
    }
  },
  computed: {
    isSidebarActiveLocal: {
      get () {
        return this.isSidebarActive
      },
      set (val) {
        if (!val) {
          this.$emit('closeSidebar')
        }
      }
    },
    isFormValid () {
      return !this.errors.any()
    },
    scrollbarTag () {
      return this.$store.getters.scrollbarTag
    }
  },
  methods: {
    /**
     * Get stocks
     */
    async getStocks (product) {
      try {
        this.progress = true
        this.stocks = []
        const querySnapshot = await db.collection('products').doc(product.id).collection('stocks').get()
        querySnapshot.forEach((doc) => {
          this.stocks.push({
            id: doc.id,
            ...doc.data()
          })
        })
      } catch (e) {
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error.'
        })
      } finally {
        this.progress = false
      }
    },
    /**
     * Exports to excel
     */
    exportToExcel () {
      import('@/vendor/Export2Excel').then(excel => {
        const list = _.cloneDeep(this.stocks)
        const headerVal = ['warehouse', 'stock']
        const headerTitle = ['Almacén', 'Stock']
        const data = this.formatJson(headerVal, list)
        excel.export_json_to_excel({
          header: headerTitle,
          data,
          filename: 'Stock por almacén - ' + this.document.sku,
          autoWidth: this.cellAutoWidth,
          bookType: 'xlsx'
        })
      })
    },
    formatJson (filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => {
        // Add col name which needs to be translated
        // if (j === 'timestamp') {
        //   return parseTime(v[j])
        // } else {
        //   return v[j]
        // }

        return v[j]
      }))
    }
  }
}
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 850px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

  &:not(.ps) {
    overflow-y: auto;
  }
}
</style>
