<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary"
              class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">

    <!--Sidebars-->
    <search :products="products" @add="addProductSidebar" :isSidebarActive="showSearchProductsSidebar"
            @closeSidebar="showSearchProductsSidebar= false"/>
    <!--End-->

    <div class="mt-6 flex items-center justify-between px-6">
      <h4>Agregar producto compuesto</h4>
      <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <component :is="scrollbarTag" class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">
      <div class="p-6">

        <!--Search product-->
        <vs-button @click="showSearchProductsSidebar= true" :disabled="progress" class="w-full" color="danger"
                   type="filled">
          Buscar producto
        </vs-button>
        <!--End-->

        <!--Added products-->
        <vs-table class="mt-8" :data="addedProducts">
          <template slot="thead">
            <vs-th>Descripción</vs-th>
            <vs-th>Nro. de Parte</vs-th>
            <vs-th>Cantidad</vs-th>
            <vs-th>Acciones</vs-th>
          </template>

          <template slot-scope="{data}">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">

              <vs-td style="width: 40%" :data="tr.description">
                <vs-textarea disabled rows="5" v-model="tr.description"></vs-textarea>
              </vs-td>

              <vs-td :data="tr.numberPart">
                <p> {{ tr.numberPart }}</p>
              </vs-td>

              <vs-td :data="tr.quantity">
                <template>
                  <vs-input style="width: 60px" min="1" type="number" v-model.number="tr.quantity"/>
                </template>
              </vs-td>

              <vs-td class="whitespace-no-wrap">
                <feather-icon class="mr-2 cursor-pointer" icon="TrashIcon"
                              svgClasses="w-5 h-5 hover:text-primary stroke-current"
                              @click.stop="deleteProduct(tr)"/>
              </vs-td>

            </vs-tr>
          </template>
        </vs-table>
        <!---->

        <vs-divider/>

        <!-- Number Part -->
        <vs-input @blur="document.numberPart= trimString(document.numberPart)" label="Nro. de Parte*"
                  v-model="document.numberPart"
                  class="w-full mt-5" name="numberPart"
                  v-validate="'required'"/>
        <span class="text-danger text-sm"
              v-show="errors.has('numberPart')">{{ errors.first('numberPart') }}</span>

        <!-- Description -->
        <vs-textarea rows="10" @blur="document.description= trimString(document.description)" label="Descripción*"
                     v-model="document.description"
                     class="mt-5 w-full" name="description"
                     v-validate="'required'"/>
        <span class="text-danger text-sm"
              v-show="errors.has('description')">{{ errors.first('description') }}</span>

        <!-- Purchase price -->
        <vs-input type="number" label="Precio de compra*" v-model.number="document.purchasePrice" class="mt-5 w-full"
                  name="purchasePrice"
                  v-validate="'required'"/>
        <span class="text-danger text-sm"
              v-show="errors.has('purchasePrice')">{{ errors.first('purchasePrice') }}</span>

        <!-- Price -->
        <vs-input type="number" label="Precio referencial de venta*" v-model.number="document.price" class="mt-5 w-full"
                  name="price"
                  v-validate="'required'"/>
        <span class="text-danger text-sm"
              v-show="errors.has('price')">{{ errors.first('price') }}</span>

      </div>
    </component>

    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid || progress">Guardar</vs-button>
      <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Cancelar</vs-button>
      <div v-if="progress" style="margin-top: 1rem; width: 100%">
        <vs-progress indeterminate color="primary"></vs-progress>
      </div>
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import trimString from '../../mixins/trimString'
import vSelect from 'vue-select'
import Search from '@/views/products/SearchProducts'

import { db, auth, FieldValue } from '@/firebase/firebaseConfig'

export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    products: {
      type: Array,
      required: true
    }
  },
  components: {
    VuePerfectScrollbar,
    vSelect,
    Search
  },
  mixins: [trimString],
  data () {
    return {
      document: {
        description: ''
      },
      settings: { // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: .60
      },
      progress: false,
      addedProducts: [],
      showSearchProductsSidebar: false
    }
  },
  watch: {
    isSidebarActive (val) {
      if (val) {
        this.document = {
          description: ''
        }
        this.addedProducts = []
        this.$validator.reset()
      }
    },
    addedProducts: {
      deep: true,
      handler () {
        console.log('Entra')
        this.document.description = ''
        this.addedProducts.forEach((p) => {
          this.document.description += p.description + ' - Cantidad: ' + p.quantity + '\n\n'
        })
        console.log(this.document.description)
      }
    }
  },
  computed: {
    isSidebarActiveLocal: {
      get () {
        return this.isSidebarActive
      },
      set (val) {
        if (!val) {
          this.$emit('closeSidebar')
        }
      }
    },
    isFormValid () {
      return !this.errors.any()
    },
    scrollbarTag () {
      return this.$store.getters.scrollbarTag
    }
  },
  methods: {
    /**
     * Save data in firestore
     * @returns {Promise<void>}
     */
    async submitData () {
      try {
        const result = await this.$validator.validateAll()
        if (result) {
          this.progress = true
          const obj = {
            stock: 0,
            ...this.document,
            state: true,
            addedProducts: [
              ...this.addedProducts
            ],
            type: 'Compuesto'
          }
          obj.numberPart = obj.numberPart.toUpperCase()
          // Validate number part
          const querySnap = await db.collection('products').where('numberPart', '==', obj.numberPart).get()
          if (querySnap.size === 0) {
            const doc = await db.collection('products').add({
              ...obj,
              uid: auth.currentUser.uid,
              createdAt: FieldValue.serverTimestamp()
            })
            obj.id = doc.id
            this.$emit('add', obj)
            this.$emit('closeSidebar')
            this.progress = false
            this.$vs.notify({
              color: 'success',
              title: 'Producto',
              text: 'Producto creado correctamente.'
            })
          } else {
            this.$vs.notify({
              color: 'warning',
              title: 'Producto',
              text: 'Número de parte no puede repetirse.'
            })
          }
        }
      } catch (e) {
        console.log(e)
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error.'
        })
      } finally {
        this.progress = false
      }
    },
    /**
     * Delete product
     */
    deleteProduct (product) {
      const indexProduct = this.addedProducts.findIndex((p) => p.id === product.id)
      this.addedProducts.splice(indexProduct, 1)
    },
    /**
     * Search product sidebar
     */
    addProductSidebar (o) {
      let findProduct = this.addedProducts.find((p) => p.id === o.id)
      if (!findProduct) {
        let obj = {
          id: o.id,
          description: o.description,
          sku: o.id.substr(0, 6),
          numberPart: o.numberPart,
          quantity: 1
        }
        this.addedProducts.push(obj)
      } else {
        findProduct.quantity++
      }
      this.showSearchProductsSidebar = false
    }
  }
}
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 60vw !important;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

  &:not(.ps) {
    overflow-y: auto;
  }
}
</style>
